<!--
 * @Author: your name
 * @Date: 2021-12-16 17:42:31
 * @LastEditTime: 2021-12-25 18:19:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \scm-admin\src\views\vendor-reconcile\index.vue
-->
<template>
  <div>
    <!-- <router-link :to="{path:'/iframe/financial-manage/supplier-settlement/statement-management/details/index',query:{id}}"> -->
    <el-button type="text" @click="handleClick">正在跳转中,请稍等...</el-button>
    <!-- </router-link> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getUserInfoByVendorId } from '@/api/vendorPurchase-api.js'
// import { removeToken } from '@/utils/auth'
import { createUniqueString } from '@/utils'
import { login } from '@/api/user'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    this.handleClick()
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    async  handleClick() {
      const deviceId = createUniqueString()
      const { vendorId, sign } = this.$route.query
      const { code, datas: { password, username }} = await getUserInfoByVendorId({ vendorId, sign })
      if (code === 0) {
        /*  const form = { username, password: '123456' || password, deviceId }
        this.$store.dispatch('user/login', form)
          .then(() => {
            this.$router.push({
              path: '/iframe/financial-manage/supplier-settlement/statement-management/details/index',
              query: {
                id
              }
            })
          }) */
        const bodyFormData = new FormData()
        bodyFormData.set('username', username)
        bodyFormData.set('password', '123456' || password)
        bodyFormData.set('deviceId', deviceId)
        const { datas: { access_token }} = await login(bodyFormData)
        sessionStorage.setItem('token', JSON.stringify(access_token))

        /* this.$store.commit('SET_TOKEN', access_token)
        setToken(access_token) */
        this.$router.push({
          path: '/iframe/financial-manage/supplier-settlement/statement-management/details/index',
          query: {
            id: vendorId
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
